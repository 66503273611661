import type { ICountry } from '#root/src/types/dto/country';
import { inject } from 'vue';
import { cdekConsoleError } from '@/utils/console-wrapper';
import useSiteInfo from '@/composables/use-site-info';
import type RestApiClient from '@/api-clients/rest-api-client';

let defaultCountries: ICountry[] = [];
const useCountries = () => {
  const restApi = inject<RestApiClient>('restApi');

  const { currentLocaleISO } = useSiteInfo();

  const setDefaultCountries = (data: ICountry[]) => {
    defaultCountries = data;
  };

  const getDefaultCountries = () => {
    return defaultCountries;
  };

  const getCountriesByLocale = async () => {
    try {
      return await restApi?.getCountries(currentLocaleISO.value);
    } catch (e) {
      cdekConsoleError(e);
      return getDefaultCountries();
    }
  };

  return {
    setDefaultCountries,
    getDefaultCountries,
    getCountriesByLocale,
  };
};

export default useCountries;
